<template>
  <div class="detail-content">
    <div class="detail_title">{{ detailContent.title }}</div>
    <div class="detail_time">{{ detailContent.createTime }}</div>
    <div v-html="detailContent.richText" class="detail_text"></div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name:"demoDetail",
  data() {
    return {
      detailContent: {},
    };
  },
  mounted() {
    this.queryByPageDataId();
  },
  methods: {
    queryByPageDataId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("https://www.claybbt.com/queryByPageDataId", {
          params: {
            language: lang,
            pageDataId: this.$route.params.id,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.detailContent = res.data.msg;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
};
</script>
<style>
.detail-content {
  width: 753px;
  margin: 0 auto;
}
.detail_title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 100;
  color: #131313;
}
.detail_time {
  height: 35px;
  font-size: 12px;
  color: #b6b6b9;
  font-weight: 100;
  line-height: 30px;
  border-bottom: 3px solid #ededed;
  margin-bottom: 20px;
  position: relative;
}
.detail_time:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 3px;
  background: #d80930;
  display: block;
  width: 26px;
}
.detail_text {
  font-size: 14px;
  color: #535354;
  line-height: 30px;
}
</style>
